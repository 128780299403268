<template>
  <div>
    <div class="mb-4">
      <div class="text-h6 font-weight-medium third--text">
        {{ $t("sessions.title") }}
      </div>
    </div>
    <v-card class="rounded-md mb-4" dark color="fifth">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
        <v-btn
          depressed
          class="mr-2"
          @click="getFilters()"
          :loading="loading.filter"
        >
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
        <v-btn
          depressed
          @click="exportSessions()"
          :loading="exportLoading"
        >
          <v-icon left>mdi-file-excel-outline</v-icon>
          {{ $t("sessions.export.title2") }}
        </v-btn>
      </div>
    </v-card>
    <v-card dark color="fifth">
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="third--text">{{ $t("sessions.list.title") }}</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn outlined color="third" v-if="meta.total">{{
          $tc("counter.session", meta.total, {
            count: $utils.pad(meta.total, 2),
          })
        }}</v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        :server-items-length="meta.total"
        sort-by="id"
        sort-desc
        dark
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td style="min-width: 160px">
                    <span>{{
                      $moment(item.created).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </td>
                  <td>{{ item.client.identifier }}</td>
                  <td>
                    <v-chip class="table-chip" color="third" dark>
                      <span class="black--text font-weight-bold">{{
                        $t("sessions.fields.type." + item.type)
                      }}</span>
                    </v-chip>
                  </td>
                  <td style="min-width: 160px">
                    {{ $moment(item.created).format("YYYY-MM-DD HH:mm:ss") }}
                  </td>
                  <td>{{ item.reseller.name }}</td>
                  <td class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="expireSession(item)"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :loading="session.id === item.id && loading.expire"
                          v-if="item.type !== 'CLIENT'"
                        >
                          <v-icon color="third"
                            >mdi-account-clock-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ $t("sessions.list.actions.expire") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
      <session-filter
        :dialog="dialog.filter"
        @applyFilter="filterItems"
      ></session-filter>
    </v-card>
    <confirm :dialog="dialog.expire" @confirm="confirmExpireSession"></confirm>
    <session-export
      :dialog="dialog.export"
      :field="exportField"
      @exportConfirm="exportConfirmed"
    ></session-export>
  </div>
</template>

<script>
import ExportMixin from "./../../mixins/commons/export";
import Confirm from "./../commons/confirm";
import SessionFilter from "./filter";
import ListMixin from "./../../mixins/commons/list";
import SessionExport from "./../commons/export.vue";

export default {
  mixins: [ListMixin, ExportMixin],
  data: () => ({
    path: "api/v1/sessions",
    dialog: {
      filter: {
        display: false,
      },
      expire: {
        display: false,
        message: null,
      },
      export: {
        display: false,
        title: null,
      },
    },
    exportField: {
      title: null,
      placeholder: null
    },
    session: {},
    loading: {
      expire: false,
    },
    exportUrl: "api/v1/sessions/export/ussd",
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("commons.created.title2"),
          align: "start",
          sortable: true,
          value: "created",
          width: 160,
        },
        {
          text: this.$t("sessions.fields.client.title"),
          align: "start",
          sortable: false,
          value: "client.identifier",
          width: 90,
        },
        {
          text: this.$t("sessions.fields.type.title"),
          align: "start",
          sortable: false,
          value: "type",
          width: 90,
        },
        {
          text: this.$t("sessions.fields.ttl.title"),
          align: "start",
          sortable: false,
          value: "ttl",
          width: 160,
        },
        {
          text: this.$t("sessions.fields.reseller.title"),
          align: "start",
          sortable: false,
          value: "reseller.name",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: null,
        },
      ];
    },
    getFilters() {
      this.dialog.filter.display = true;
    },
    expireSession(session) {
      this.session = session;
      this.dialog.expire = {
        display: true,
        message: this.$t("sessions.expire.confirm"),
      };
    },
    async confirmExpireSession() {
      this.loading.expire = true;
      try {
        await this.request({
          url: `api/v1/sessions/${this.session.id}/expire`,
          method: "PUT",
          messages: {
            500: true,
            200: this.$t("sessions.expire.success"),
          },
        });
        this.getItems();
      } catch (error) {
        // empty
      }
      this.loading.expire = false;
    },
    exportSessions() {
      this.exportField = {
        title: this.$t("sessions.fields.created.description"),
        placeholder: this.$t("sessions.fields.created.title"),
      };
      this.dialog.export = {
        display: true,
        title: this.$t("sessions.export.title"),
      };
    }
  },
  components: {
    SessionFilter,
    Confirm,
    SessionExport,
  },
};
</script>