export default {
  data: () => ({
    exportLoading: false,
    exportUrl: null
  }),
  methods: {
    async exportConfirmed(daterange) {
      if(!this.exportUrl) return;

      if (!daterange) {
        this.notify({ message: this.$t("errors.daterange_is_empty") });
        return;
      }
      this.exportLoading = true;
      try {
        const startDate = daterange[0] + " 00:00:00";
        const endDate = daterange[1] + " 23:59:59";

        const response = await this.request({
          url: this.exportUrl,
          responseType: "blob",
          params: {
            created_gte: startDate,
            created_lte: endDate,
          },
          messages: {
            500: true
          }
        });
        const filename = this.$utils.getFilenameFromContentDisposition(
          response.headers["content-disposition"]
        );
        this.$filesaver(response.data, filename || `export${daterange[0]}-${daterange[1]}.xlsx`);
      } catch (error) {
        // empty
      }
      this.exportLoading = false;
    }
  }
}