<template>
  <v-dialog
    v-model="dialog.display"
    max-width="500"
    :overlay-opacity="'0.5'"
    :overlay-color="$vuetify.theme.themes.light.third"
    persistent
  >
    <v-card dark>
      <v-card-title>
        <div class="text--h6">{{ dialog.title }}</div>
        <v-spacer></v-spacer>
        <v-btn icon text class="ml-auto" @click="dialog.display = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <v-dialog
          ref="dialogDaterange"
          v-model="dialogDaterange"
          :return-value.sync="daterange"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              append-icon="mdi-calendar"
              v-bind="{attrs, ...field}"
              v-on="on"
              readonly
              v-model="dateRangeText"
            ></v-text-field>
          </template>
          <v-date-picker dark range v-model="daterange" color="primary" scrollable v-bind="dateField">
            <v-spacer></v-spacer>
            <v-btn text color="third" @click="dialogDaterange = false">
              {{ $t("btn.cancel") }}
            </v-btn>
            <v-btn
              text
              color="secondary"
              @click="$refs.dialogDaterange.save(daterange)"
            >
              {{ $t("btn.ok") }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="
            () => {
              dialog.display = false;
            }
          "
          color="third"
          text
          >{{ $t("btn.cancel") }}</v-btn
        >
        <v-btn @click="exportConfirm()" :disabled="daterange.length !== 2" color="secondary" text>{{
          $t("btn.export")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
          title: null
        };
      },
    },
    field: {
      type: Object,
      default: function() {
        return {}
      }
    },
    dateField: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data: () => ({
    dialogDaterange: false,
    daterange: [],
  }),
  methods: {
    exportConfirm() {
      this.$emit(
        "exportConfirm",
        this.daterange.length === 2
          ? this.$utils.daterangeAlign(this.daterange)
          : null
      );
      this.dialog.display = false;
    },
  },
  computed: {
    dateRangeText() {
      if (this.daterange.length !== 2) return;
      const dates = this.$utils.daterangeAlign(this.daterange);
      return dates.join(" - ");
    },
  },
};
</script>