<template>
  <div>
    <session></session>
  </div>
</template>

<script>
import Session from "./../../components/sessions/list";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("sessions.title")
    };
  },
  components: {
    Session
  }
};
</script>